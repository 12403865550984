import React from 'react';

const SwordOfTheSpiritIcon = () => {
    const fillColor = "#D2B48C";
    const crossBibleColor = "#0DA5CE";
    const swordColor = "#0DA5CE";
    const crossColor = "#FFF";
    return (
        <svg width="200" height="200" version="1.1" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.3333 0 0 -1.3333 0 257.01)">
        <g transform="matrix(.077819 0 0 .077819 0 42.756)" fillRule="evenodd">
        <path d="m0 1927.6h1927.6v-1927.6h-1927.6v1927.6" fill="#fff" fillOpacity="0.0"/>
        <path d="m162.57 1243.8 196.27-280.04-196.27-280.04 678.12 156.96-156.96-678.12 280.04 196.27 280.04-196.27-156.96 678.12 678.12-156.96-196.26 280.04 196.26 280.04-678.12-156.96 156.96 678.12-280.04-196.27-280.04 196.27 156.96-678.12-678.12 156.96" fill={crossColor} stroke="#000" strokeWidth="10"/>
        <path d="m765.69 1636.3 198.09-567.66 198.09 567.65-142.88-617.3 617.31 142.88-567.66-198.09 567.65-198.09-617.3 142.88 142.88-617.31-198.09 567.65-198.09-567.65 142.88 617.3-617.31-142.88 567.65 198.09-567.65 198.09 617.3-142.88-142.88 617.31" fill="#fff"/>
        <path d="m1595.9 331.66-156.87 36.692c-319.46 340.41-638.92 680.83-958.39 1021.2-12.868-12.86-25.739-25.73-38.61-38.6 8.633-21.24 4.36-46.58-12.84-63.78-22.894-22.9-60.23-22.9-83.136 0-22.871 22.88-22.871 60.22 0.023 83.11 17.195 17.2 42.535 21.48 63.785 12.84 12.598 12.6 25.2 25.21 37.797 37.8-70.015 47.34-125.1 109.59-176.02 176.02-23.547-4.54-48.903 2.27-67.078 20.45-29.079 29.07-29.079 76.5 0 105.57 29.074 29.08 76.504 29.08 105.58 0 18.179-18.17 24.992-43.53 20.441-67.08 66.434-50.91 128.69-106.01 176.02-176.02l37.785 37.78c-8.637 21.25-4.34 46.61 12.855 63.8 22.875 22.88 60.219 22.88 83.09 0 22.906-22.9 22.899-60.24 0.027-83.11-17.199-17.2-42.558-21.49-63.8-12.86-12.871-12.87-25.742-25.75-38.617-38.62l1021.3-958.36 36.69-156.87" fill={swordColor}/>
        <g>
            <path d="m1509.6 392.15-80.42 28.098-917.27 958.91 304.08-293.4zm25.76 25.77-28.1 80.41-958.9 917.27 293.2-303.88 693.8-693.8"/>
            <path d="m619.76 1497.5c-16.985-7.58-37.664-4.43-51.567 9.48-17.988 17.98-17.988 47.33 4e-3 65.32 10.36 10.36 24.485 14.75 38.039 13.17-8.32-1.67-16.254-5.71-22.675-12.13-17.372-17.37-17.372-45.7-4e-3 -63.06 9.867-9.87 23.281-14.13 36.203-12.78"/>
            <path d="m406.51 1285.5c-16.985-7.58-37.66-4.42-51.567 9.48-17.984 17.99-17.984 47.34 4e-3 65.33 10.36 10.35 24.485 14.74 38.039 13.17-8.316-1.67-16.254-5.71-22.679-12.14-17.364-17.37-17.364-45.69 0-63.06 9.867-9.86 23.281-14.13 36.203-12.78"/>
            <path d="m308.16 1645.1c9.625 21.56 5.617 47.83-12.035 65.47-22.84 22.84-60.098 22.84-82.945 0-13.149-13.15-18.723-31.09-16.727-48.3 2.125 10.56 7.254 20.63 15.414 28.8 22.051 22.05 58.02 22.05 80.067 0 12.531-12.53 17.937-29.56 16.226-45.97"/>
            <path d="m303.45 1570.9c3.074 12.4 9.492 24.23 19.25 33.98 9.758 9.76 21.578 16.18 33.989 19.25l6.738-6.73c-11.149-5-21.895-12.31-31.418-21.82-9.52-9.52-16.824-20.28-21.824-31.42l-6.735 6.74"/>
            <path d="m331.48 1536.7c3.965 13.3 11.442 26.18 22.321 37.06s23.758 18.35 37.058 22.31l6.735-6.72c-12.074-5.94-23.867-14.28-34.481-24.9-10.621-10.62-18.968-22.41-24.894-34.48l-6.739 6.73"/>
            <path d="m362.59 1505.6c3.961 13.3 11.441 26.18 22.316 37.06 10.879 10.88 23.758 18.35 37.059 22.32l6.734-6.74c-12.074-5.93-23.867-14.27-34.484-24.89s-18.965-22.41-24.895-34.48l-6.73 6.73"/>
            <path d="m396.75 1477.6c3.074 12.41 9.496 24.24 19.25 33.99s21.582 16.17 33.992 19.25l6.734-6.74c-11.148-5-21.898-12.3-31.417-21.82-9.52-9.52-16.825-20.27-21.825-31.41l-6.734 6.73"/>
            <path d="m430.61 1449.2c2.274 11.61 7.75 22.49 16.496 31.24 8.75 8.74 19.629 14.22 31.243 16.49l6.73-6.73c-10.308-4.17-20.129-10.54-28.664-19.07-8.531-8.54-14.91-18.36-19.074-28.67l-6.731 6.74"/>
        </g>
        <path d="m991.32 1294.1 482.74-2.63c-43.82-85.1-40.75-149.09 9.2-191.99l-465.49-418.56h-559.12c-92.997 47.207-114.75 120.06-65.29 218.56l597.96 394.62" fill={crossBibleColor}/>
        <g fill="#fff">
            <path d="m995.24 1280.9 439.67-2.4-486.84-365.86h-510.86zm126.6-32.37h44.95 44.3c-23.53-17.22-47.7-34.85-72.52-52.94 20.3 0.17 40.5 0.37 60.48 0.56 19.88 0.2 39.59 0.36 59.24 0.56-21.02-16.37-42.55-33.16-64.59-50.33-20.63-0.39-41.35-0.75-62.2-1.15-21.03-0.4-42.25-0.81-63.57-1.21-41.32-30.12-84.414-61.56-129.48-94.43-47.164-34.41-96.59-70.421-148.34-108.17-19.613-1.023-39.406-2.054-59.234-3.086-19.961-1.043-40.141-2.093-60.359-3.156 55.715 38.641 108.78 75.493 159.43 110.65 48.344 33.55 94.551 65.61 138.81 96.35-22.332-0.41-44.816-0.83-67.477-1.26-22.836-0.42-45.824-0.86-68.996-1.29 28.239 18.39 55.778 36.32 82.61 53.81 21.945 0.19 43.785 0.41 65.418 0.62 21.491 0.21 42.821 0.4 64.041 0.61 26.5 18.43 52.32 36.35 77.49 53.86"/>
            <path d="m1432.7 1159.7-1.09 6.33-0.77 6.46-0.48 6.56-0.16 6.67 0.14 6.77 0.43 6.86 0.73 6.96 1.02 7.06 1.32 7.14 1.59 7.25 1.88 7.34 2.17 7.46 2.43 7.54 0.37 1.02-478.71-359.76-2.313-6.493-3.179-9.761-2.778-9.492-2.379-9.211-1.988-8.93-1.597-8.668-1.231-8.391-0.855-8.117-0.489-7.847-0.14-7.594 0.207-7.324 0.543-7.071 0.882-6.816 1.2-6.586 1.515-6.356 1.825-6.117 2.136-5.902 2.438-5.707 2.742-5.508 3.039-5.324 3.344-5.141 3.652-4.98 3.953-4.809 4.258-4.66 4.563-4.492 4.886-4.348 5.196-4.199 5.492-4.043 5.821-3.895 6.12-3.753 5.33-2.989 448.17 402.98-3.35 3.63-4.15 4.96-3.85 5.13-3.56 5.27-3.25 5.41-2.95 5.57-2.66 5.73-2.33 5.85-2.02 5.99-1.72 6.12-1.39 6.23"/>
            <path d="m930.96 752.6-3.043 7.122-2.637 7.355-2.238 7.563-1.824 7.796-1.399 8.024-0.941 8.258-0.496 8.465-0.024 8.691 0.446 8.918 0.937 9.152 1.418 9.371 1.922 9.61 2.418 9.851 2.934 10.102 3.461 10.355 1.16 3.094h-531.43l-0.742-1.535-3.84-8.617-3.418-8.434-3.012-8.258-2.597-8.066-2.203-7.879-1.785-7.695-1.403-7.508-0.996-7.313-0.621-7.132-0.227-6.954 0.145-6.773 0.52-6.59 0.89-6.433 1.258-6.266 1.617-6.121 1.992-5.969 2.364-5.851 2.738-5.727 3.109-5.606 3.504-5.492 3.891-5.39 4.293-5.289 4.687-5.184 5.114-5.074 5.535-4.981 5.961-4.871 6.375-4.765 6.816-4.641 7.238-4.531 7.684-4.414 5.414-2.871h515.51l-4.848 4.191-5.68 5.25-5.472 5.434-5.211 5.621-4.957 5.8-4.684 6.012-4.39 6.223-4.079 6.437-3.746 6.653-3.402 6.882"/>
        </g>
        <g fill={fillColor}>
            <path d="m928.43 872.23v-19.25l-514.71 9.625z"/>
            <path d="m928.43 832.88v-19.246l-514.71 9.625z"/>
            <path d="m928.43 793.55v-19.254l-514.71 9.625z"/>
            <path d="m948.08 758.34v-19.25l-514.71 9.629z"/>
            <path d="m967.51 725.84v-19.25l-514.71 9.625z"/>
            <path d="m1445.6 1243.4 0.89-18.33-454.91-333.24z"/>
            <path d="m1438.2 1207.4 0.88-18.33-449.89-336.25z"/>
            <path d="m1435.4 1176.7 0.88-18.33-460.92-361.33z"/>
            <path d="m1445.1 1150.1 4.37-14.84-442.88-351.3z"/>
            <path d="m1459.6 1127.9 4.37-14.84-443.89-372.38z"/>
        </g>
        </g>
        </g>
        </svg>
    );
};

export default SwordOfTheSpiritIcon;